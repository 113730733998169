import { createContext, useContext } from 'react'
import { CartContextTypeAnotation } from '../../App'

export const CartContext = createContext<CartContextTypeAnotation | undefined>(undefined)

export function useCartContext() {
  const cartCount = useContext(CartContext)

  if (cartCount === undefined) {
    throw new Error('useCartContext must be used with data, can not be undefined.')
  }

  return cartCount
}
