import * as React from 'react'
import Navigation from './navigation'
import { CartContext } from './context/CartContext/cartContext'
import { useFetchUserCartItemsNumberQuery } from './generated/graphql'
import { ERROR_TOAST } from './constants'
import { useToast } from '@chakra-ui/core'
import { get } from 'lodash'
import { ApolloError } from 'apollo-boost'
import { useEffect } from 'react'

export interface CartContextTypeAnotation {
  count: number
  refetch: () => void
}

const App: React.FC = () => {
  const toast = useToast()
  const [cartCount, setCartCount] = React.useState<CartContextTypeAnotation>({
    count: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    refetch: () => {}
  })

  // Handler for fetching number of items in cart.
  const { data: userCartItemsCount, refetch: refetchCartItemsCount } =
    useFetchUserCartItemsNumberQuery({
      onError: (err: ApolloError) =>
        toast({
          description: 'Something went wrong, failed to count items in your cart.',
          ...ERROR_TOAST
        })
    })

  // UseEffect to set cart count when userCartItemsCount changes
  useEffect(() => {
    const cartItemsCount = get(userCartItemsCount, 'fetchUserCartItemsNumber.count') || 0

    setCartCount({ count: cartItemsCount, refetch: refetchCartItemsCount })
  }, [userCartItemsCount, refetchCartItemsCount])

  useEffect(() => {
    refetchCartItemsCount()
  }, [refetchCartItemsCount])
  //
  return (
    <CartContext.Provider value={cartCount}>
      <Navigation />
    </CartContext.Provider>
  )
}

export default App
